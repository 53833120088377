import { compile } from "path-to-regexp";

// helpers
import { buildQueryString } from "../helpers/api";

export const pathToUrl = (path, params) => compile(path)(params);

export const buildUrl = (path, params) => {
  const query = buildQueryString(params);

  return [path, query].filter(Boolean).join("");
};
