import useSWR from "swr";
import dayjs from "dayjs";

// helpers
import getReportingPeriod from "../helpers/getReportingPeriod";


const SWR_KEY = "reporting-date";

const getInitialReportingDate = () => {
  const fromStorage = sessionStorage.getItem(SWR_KEY);

  return fromStorage ? JSON.parse(fromStorage) : getReportingPeriod();
};

const fetchReportingDate = () => {
  const fromStorage = sessionStorage.getItem(SWR_KEY);

  return fromStorage ? JSON.parse(fromStorage) : getReportingPeriod();
};

export default function useReportingDate() {
  const { data, mutate } = useSWR(SWR_KEY, fetchReportingDate);

  const [reportingDateFrom, reportingDateTo] = data || getInitialReportingDate();
  const handleSetReportingDate = (e) => {
    if (e.every(date => dayjs(date).isSame(dayjs(), "month"))) {
      sessionStorage.removeItem(SWR_KEY);
      mutate([null, null]);

      return;
    }

    sessionStorage.setItem(SWR_KEY, JSON.stringify(e));
    mutate(e);
  };


  return [
    [reportingDateFrom, reportingDateTo],
    handleSetReportingDate,
  ];
}
