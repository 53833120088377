// helpers
import { DASHBOARD_JOB_TITLE_PERMISSIONS } from "../constants/permissions";
import { useGetUser, useActiveDealership } from "../swr/userManagement";


function useProfile() {
  const {
    data: profileData,
    mutate: refetch,
  } = useGetUser();

  const { data: activeDealershipData } = useActiveDealership();

  if (!profileData) {
    return { activeDealership: activeDealershipData };
  }

  const permissions = {
    dashboard: {
      service: DASHBOARD_JOB_TITLE_PERMISSIONS.dashboardService.includes(profileData.jobTitle),
      parts: DASHBOARD_JOB_TITLE_PERMISSIONS.dashboardParts.includes(profileData.jobTitle),
      "op-codes": true,
      reports: true,
      "labor-matrix": DASHBOARD_JOB_TITLE_PERMISSIONS.laborMatrix.includes(profileData.jobTitle),
      "labor-types": true,
    },
    isAdmin: ["ADMIN", "ADMIN_SUPER"].includes(profileData.role),
    isSummaryView: ["ADMIN", "ADMIN_SUPER", "MANAGER", "MANAGER_SUPER"].includes(profileData.role),
  };
  
  if (DASHBOARD_JOB_TITLE_PERMISSIONS.dashboardService.includes(profileData.jobTitle)) {
    permissions.dashboard.service = true;
  }

  if (profileData.devFeatures) {
    permissions.dashboard.service = true;
    permissions.dashboard.parts = true;
    permissions.dashboard["op-codes"] = true;
    permissions.dashboard["labor-matrix"] = true;
  }

  return {
    ...profileData,
    refetch,
    permissions,
    activeDealership: activeDealershipData
  };
}

export default useProfile;
