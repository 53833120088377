import dayjs from "dayjs";

export default function getReportingPeriod() {
  const reportingDateRange = dayjs().format("DD") < 3
    ? [
      dayjs().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
      dayjs().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
    ]
    : [null, null];

  return reportingDateRange;
}
