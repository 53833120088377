import React from "react";
import { Route, Redirect } from "react-router-dom";
import { withMsal } from "@azure/msal-react";
import PropTypes from "prop-types";

import ScrollToTop from "../elements/ScrollToTop";

import { userHasAccessDepartment, userIsAuthenticated } from "../helpers/permissions";
import { requestScopes } from "../libs/msalAuth";

const ProtectedRoute = ({
  component: Component,
  permissions,
  departmentsPermissions,
  msalContext,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => userIsAuthenticated()
        ? (userHasAccessDepartment(permissions, departmentsPermissions)
          ? (<>
            <Component {...props} />
            <ScrollToTop />
          </>)
          : <Redirect to="/not-found-404" />
        )
        : msalContext.instance.loginRedirect(requestScopes)
      }
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType,
  permissions: PropTypes.array,
  departmentsPermissions: PropTypes.array,
  msalContext: PropTypes.object
};

export default withMsal(ProtectedRoute);
