import { PublicClientApplication, EventType } from "@azure/msal-browser";

// helpers
import axios, { refetchToken } from "../libs/Api";
import * as msalConfig from "../constants/msalAuthConfig.constants";

export const msalInstance = new PublicClientApplication(msalConfig.MSAL_AUTH_CONFIG);


export async function msalInitialize() {
  await msalInstance.initialize();
  const accounts = msalInstance.getAllAccounts();

  if (!msalInstance.getActiveAccount() && accounts.length > 0) {
    const newAccount = accounts[0];
    msalInstance.setActiveAccount(newAccount);
  }

  msalInstance.addEventCallback(async (event) => {
    switch (true) {
      case event.eventType === EventType.LOGIN_SUCCESS && !!event.payload.account: {
        const newAccount = event.payload.account;
        msalInstance.setActiveAccount(newAccount);
  
        localStorage.setItem("accessToken", event.payload.accessToken);
        
        break;
      }
      case event.eventType === EventType.HANDLE_REDIRECT_END && !accounts.length: {
        msalInstance.loginRedirect(msalConfig.MSAL_REQUEST_SCOPES);

        break;
      }
      case event.eventType === EventType.HANDLE_REDIRECT_END: {
        await msalInstance.acquireTokenSilent({
          ...msalConfig.MSAL_REQUEST_SCOPES,
          account: accounts[0]
        })
          .then((resp) => resp && localStorage.setItem("accessToken", resp.accessToken))
          .catch(() => msalInstance.loginRedirect(msalConfig.MSAL_REQUEST_SCOPES));

        break;
      }
      default:
        break;
    }
  });

  axios.interceptors.response.use(
    response => response,
    error => refetchToken(error, msalInstance, msalConfig.MSAL_REQUEST_SCOPES, accounts)
  );

  return { instance: msalInstance, user: msalInstance.getActiveAccount() };
}
