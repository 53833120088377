import React from "react";
import PropTypes from "prop-types";

const ChevronUp = ({ width, height, color = "#0069FF" }) => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" style={{ width, height }}>
    <title>4E970C4B-F8F8-4D73-96AE-F43C36496281</title>
    <g id="Onboarding" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Onboarding---Overview-Expanded" transform="translate(-140.000000, -1111.000000)">
        <g id="icon/16/chevron-left" transform="translate(140.000000, 1111.000000)" fill={color}>
          <path
            d="M7.91428752,7.45320885 L10.1997802,5.21435892 C10.5943095,4.82788119 10.6008366,4.19474985 10.2143589,3.8002205 C9.82788117,3.40569115 9.19474984,3.39916402 8.80022049,3.78564176 L5.80022049,6.72441727 C5.40563304,7.11095191 5.39917068,7.74419749 5.78578748,8.13870443 L8.78578748,11.1999289 C9.17234737,11.5943778 9.80548005,11.6007731 10.1999289,11.2142132 C10.5943778,10.8276533 10.600773,10.1945206 10.2142132,9.80007175 L7.91428752,7.45320885 Z"
            id="Line-Copy"
            transform="translate(8.000000, 7.500000) rotate(-270.000000) translate(-8.000000, -7.500000) "
          />
        </g>
      </g>
    </g>
  </svg>
);

ChevronUp.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
};

export default ChevronUp;
