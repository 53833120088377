import { PublicClientApplication } from "@azure/msal-browser";

export const authorities = {
  signUpSignIn: process.env.REACT_APP_B2C_SIGNIN,
  forgotPassword: process.env.REACT_APP_B2C_FORGOT_PASSWORD,
  editProfile: process.env.REACT_APP_B2C_EDIT_PROFILE
};

export const config = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENTID,
    authority: process.env.REACT_APP_B2C_SIGNIN,
    knownAuthorities: [process.env.REACT_APP_B2C_KNOWN_AUTHORITIES],
    validateAuthority: false,
    postLogoutRedirectUri: process.env.REACT_APP_MSAL_LOGOUT_REDIRECT,
    redirectUri: process.env.REACT_APP_MSAL_LOGIN_REDIRECT
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false
  }
};

export const requestScopes = {
  scopes: ["openid", process.env.REACT_APP_MSAL_CLIENTID]
};

export default new PublicClientApplication(config);
