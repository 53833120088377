import React from "react";
import PropTypes from "prop-types";
import { BsInfoCircleFill } from "react-icons/bs";
import { IoMdWarning } from "react-icons/io";
import classNames from "classnames";

// assets
import "../styles/shared/label-error-provider.scss";


function LabelErrorProvider({ error, label, info, fullWidth, children, className = "", disabled, labelPlacement = "top", ...rest }) {

  const labelErrorClassName = classNames(
    "time-label-error-provider",
    className,
    `time-label-error-provider_label-placement-${labelPlacement}`,
    {
      "time-label-error-provider__full-width": !!fullWidth,
      "time-label-error-provider__disabled": !!disabled,
      "time-label-error-provider__show-error": !!error,
    }
  );

  return (
    <div className={labelErrorClassName} { ...rest }>
      {
        label
          ? <span className="time-label-error-provider__label">
            { label }
          </span>
          : null
      }
      { children }
      {
        info && !error
          ? (
            <div className="time-label-error-provider__info">
              <BsInfoCircleFill className="time-label-error-provider__info-icon"/>
              <span className="time-label-error-provider__info-label">{ info }</span>
            </div>
          )
          : null
      }
      {
        error
          ? (
            <div className="time-label-error-provider__error">
              <IoMdWarning className="time-label-error-provider__error-icon"/>
              <span className="time-label-error-provider__error-label">{ error }</span>
            </div>
          )
          : null
      }
    </div>
  );
}


LabelErrorProvider.propType = {
  error: PropTypes.node,
  label: PropTypes.node,
  info: PropTypes.node,
  labelPlacement: PropTypes.oneOf(["top", "left"]),
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
};

export default LabelErrorProvider;
