import React from "react";
import PropTypes from "prop-types";
import { Checkbox as AntdCheckbox } from "antd";
import classNames from "classnames";

// helpers
import LabelErrorProvider from "../LabelErrorProvider";

// assets
import "../../styles/shared/checkbox.scss";


function Checkbox(props) {
  const {
    children,
    wrapperClassName,
    className = "",
    size = "regular",
    error,
    label,
    disabled,
    labelPlacement,
    type = "primary",
    ...rest
  } = props;

  const checkboxClassName = classNames(
    "time-checkbox",
    className,
    `time-checkbox_size-${size}`,
    `time-checkbox_type-${type}`
  );

  return (
    <LabelErrorProvider
      label={label}
      error={error}
      disabled={disabled}
      labelPlacement={labelPlacement}
      className={`time-checkbox-wrapper_size-${size} ${wrapperClassName}`}
    >
      <AntdCheckbox
        disabled={disabled}
        data-testid="time-checkbox"
        { ...rest }
        className={checkboxClassName}
      >
        { children }
      </AntdCheckbox>
    </LabelErrorProvider>
  );
}

Checkbox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(["regular", "small"]),
  label: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.oneOf(["primary", "secondary"]),
};

export default Checkbox;
