import axios from "./Api";

export async function getNotifications({ userId, dealershipId }, type) {
  const url = `User/${userId}/Dealerships/${dealershipId}/${type}`;
  const result = await axios.get(url);

  return result.data;
}

export async function updateNotifications({ userId, dealershipId, body }) {
  const url = `User/${userId}/Dealerships/${dealershipId}/notifications/IsRead`;
  await axios.put(url, body);
}

export async function deleteNotification({ userId, dealershipId, notificationId }) {
  const url = `User/${userId}/Dealerships/${dealershipId}/notifications/${notificationId}`;

  await axios.delete(url);
}

export async function deleteSelectedNotification({ userId, dealershipId, body }) {
  const url = `User/${userId}/Dealerships/${dealershipId}/notifications/delete`;
  await axios.put(url, body);
}

export async function sendNotification({
  userId,
  dealershipId,
  source = "",
  link = "",
  description = "",
  sourceUserId = "",
  linkFileType = ""
}) {
  const query = `?Source=${source}&Link=
    ${link}&NotificationType=internal&Description=
    ${description}&LinkType=FILEURL&SourceUserId=${sourceUserId}&LinkFileType=${linkFileType}`;
  const url = `User/${userId}/Dealerships/${dealershipId}/notifications/${query}`;
  await axios.post(url);
}

export async function sendSupportMessage({ body }) {
  const response = await axios.post(
    "contactTaiEmail",
    body,
    {
      baseURL: `${process.env.REACT_APP_API_URL}/notify/`,
      headers: {
        "TAI-Sub-Key": process.env.REACT_APP_REPORTS_API_KEY
      }
    }
  );

  return response;
}
