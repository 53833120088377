export default {
  notifications: "/api/User/:userId/Dealerships/:dealershipId/notifications",
  externalReportsSend: "/api/Users/:userId/Dealerships/:dealershipId/ExternalReportSend",
  dealershipUsers: "/GetUsersFromDealership",
  administrationUrls: {
    serviceThresholds: "/api/Users/:userId/Dealerships/:dealershipId/Administration/ServiceOptionsThresholds",
    partsThresholds: "/api/Users/:userId/Dealerships/:dealershipId/Administration/PartsOptionsThresholds",
    serviceHeatSheet: "/api/Users/:userId/Dealerships/:dealershipId/Administration/ServiceOptionsHeatSheet",
    partsHeatSheet: "/api/Users/:userId/Dealerships/:dealershipId/Administration/PartsOptionsHeatSheet",
    serviceExpenses: "/api/Users/:userId/Dealerships/:dealershipId/Administration/ServiceExpenses",
    partsExpenses: "/api/Users/:userId/Dealerships/:dealershipId/Administration/PartsExpenses",
    serviceAverages: "/api/Users/:userId/Dealerships/:dealershipId/Administration/ServiceOptionsAverages",
    partsAverages: "/api/Users/:userId/Dealerships/:dealershipId/Administration/PartsOptionsAverages",
    partsInventory: "/api/Users/:userId/Dealerships/:dealershipId/Administration/PartsOptionsInvAnalysis",
    partsWholeSale: "/api/Users/:userId/Dealerships/:dealershipId/Administration/PartsOptionsWholeSaleAnalysis",
    resetThresholds: "/api/Users/:userId/Dealerships/:dealershipId/Administration/ResetThresholds",
    groupDealershipInfo: "/api/Users/:userId/Dealerships/:dealershipId/Administration/GroupDealershipInfo",
    updateDealershipInfo: "/api/Users/:userId/Dealerships/:dealershipId/Administration/UpdateDealershipInfo",
    aiStatementAccountMapping: "/api/Users/:userId/Dealerships/:dealershipId/Administration/AIStatementAccountMapping",
    aiStatementAccountOptions: "/api/Users/:userId/Dealerships/:dealershipId/Administration/AIStatementAccountOptions",
    technicianRoles: "/api/Users/:userId/Dealerships/:dealershipId/Administration/TechnicianRoles",
    technicianShops: "/api/Users/:userId/Dealerships/:dealershipId/Administration/TechnicianShops",
    maintOpCodes: "/api/Users/:userId/Dealerships/:dealershipId/Administration/MaintOpCodes",
  },
  userManagementUrls: {
    userDealerships: "/GetUserDealershipMembership",
    getUser: "/GetUser",
  },
  aiStatement: {
    statementMode: "/api/Users/:userId/Dealerships/:dealershipId/StatementModel"
  },
  dashboard: {
    repairOrders: "/api/Users/:userId/Dealerships/:dealershipId/RepairOrders",
    statementDetail: "/api/Users/:userId/Dealerships/:dealershipId/StatementDetail",
    monthlySalesByCategory: "/api/Users/:userId/Dealerships/:dealershipId/MonthlySalesByCategory",
    monthlyRepairOrderCount: "/api/Users/:userId/Dealerships/:dealershipId/MonthlyRepairOrderCount",
    monthlyNumbers: "/api/Users/:userId/Dealerships/:dealershipId/MonthlyNumbers",
    heatSheet: "/api/Users/:userId/Dealerships/:dealershipId/HeatSheet",
    laborTypeAnalysis: "/api/Users/:userId/Dealerships/:dealershipId/LaborTypeAnalysis",
    warrantyAudit: "/api/Users/:userId/Dealerships/:dealershipId/RepairOrderWarrantyAnalysisAudit",
    repairOrderDiscounts: "/api/Users/:userId/Dealerships/:dealershipId/RepairOrderDiscounts",
    repairOrderUsedCars: "/api/Users/:userId/Dealerships/:dealershipId/RepairOrderUCI",
    laborTypeAnalysisDetails: "/api/Users/:userId/Dealerships/:dealershipId/LaborTypeAnalysisDetails",
    MonthlyAverages: "/api/Users/:userId/Dealerships/:dealershipId/MonthlyAverages",
    technicianPerformanceObjects: "/api/Users/:userId/Dealerships/:dealershipId/TechnicianPerformanceObjects",
    technicianPerformanceEfficiency: "/api/Users/:userId/Dealerships/:dealershipId/TechPerformanceEff",
    inventoryAnalysis: "/api/Users/:userId/Dealerships/:dealershipId/InventoryAnalysis",
    inventoryAnalysisTotals: "/api/Users/:userId/Dealerships/:dealershipId/InventoryAnalysisTotals",
    averageGPPercent: "/api/Users/:userId/Dealerships/:dealershipId/AverageGPPercent",
    wholesaleAnalysis: "/api/Users/:userId/Dealerships/:dealershipId/WholesaleAnalysis",
    opCodeAnalysis: "/api/Users/:userId/Dealerships/:dealershipId/OpCodeAnalysis",
    searchROs: "/api/Users/:userId/Dealerships/:dealershipId/Search/SearchROs",
    partsNoBinLocation: "/api/Users/:userId/Dealerships/:dealershipId/PartsNoBinLocation",
    partsNeverSold: "/api/Users/:userId/Dealerships/:dealershipId/PartsNeverSold",
    partsVerifyQty: "/api/Users/:userId/Dealerships/:dealershipId/PartsVerifyQty",
    partsDelStatus: "/api/Users/:userId/Dealerships/:dealershipId/PartsDelStatus",
    partsOnOpenROs: "/api/Users/:userId/Dealerships/:dealershipId/PartsOnOpenROs",
    partsInvAnalysisByStatus: "/api/Users/:userId/Dealerships/:dealershipId/PartsInvAnalysisByStatus",
    partsEmerPurchase: "/api/Users/:userId/Dealerships/:dealershipId/PartsEmerPurchase",
    repairOrderDetailByLine: "/api/Users/:userId/Dealerships/:dealershipId/RepairOrderDetailByLine",
    roCount: "/api/Users/:userId/Dealerships/:dealershipId/ROCount",
    roNotes: "/api/Users/:userId/Dealerships/:dealershipId/RONote",
    roNotesBatch: "/api/Users/:userId/Dealerships/:dealershipId/RONoteBatch",
    opCodeAnalysisDetails: "/api/Users/:userId/Dealerships/:dealershipId/OpCodeAnalysisDetails",
    laborTypeAnalysisFullDetails: "/api/Users/:userId/Dealerships/:dealershipId/LaborTypeAnalysisFullDetails",
  },
  AiMatrixConfig: "/api/Users/:userId/Dealerships/:dealershipId/AiMatrixConfig",
  AiMatrixPartsConfig: "/api/Users/:userId/Dealerships/:dealershipId/AiMatrixPartsConfig",
  DealershipOnboarding: "/api/DealershipOnboarding/DealershipGroup/:groupId/Onboarding/:onboardingToken",
  ManufacturerExpenses: "/api/Users/:userId/Dealerships/:groupId/ManufacturerExpenses",
  SaveOnboarding: "/onboarding/SaveOnboarding",
  ActiveSystemNotifications: "/api/Users/:userId/Dealerships/:dealershipId/ActiveSystemNotifications",
};
