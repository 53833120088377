const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito


export const MSAL_AUTHORITIES = {
  signUpSignIn: process.env.REACT_APP_B2C_SIGNIN,
  forgotPassword: process.env.REACT_APP_B2C_FORGOT_PASSWORD,
  editProfile: process.env.REACT_APP_B2C_EDIT_PROFILE
};

export const MSAL_AUTH_CONFIG = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENTID,
    authority: process.env.REACT_APP_B2C_SIGNIN,
    knownAuthorities: [process.env.REACT_APP_B2C_KNOWN_AUTHORITIES],
    validateAuthority: false,
    postLogoutRedirectUri: process.env.REACT_APP_MSAL_LOGOUT_REDIRECT,
    redirectUri: process.env.REACT_APP_MSAL_LOGIN_REDIRECT
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  }
};

export const MSAL_REQUEST_SCOPES = {
  scopes: ["openid", process.env.REACT_APP_MSAL_CLIENTID]
};
