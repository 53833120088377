import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components
import HeaderMain from "../components/Header";
const AIDashboard = React.lazy(() => import("../pages/AIDashboard"));
const AIReviews = React.lazy(() => import("../components/AIReviews"));
const SupportPage = React.lazy(() => import("../pages/SupportPage"));
const ProfileSettingsPage = React.lazy(() => import("../pages/ProfileSettingsPage"));
const NotificationsPage = React.lazy(() => import("../pages/NotificationsPage"));
const Administration = React.lazy(() => import("../components/Administration"));
const AIStatementPage = React.lazy(() => import("../pages/AIStatementPage"));
const NotFound404 = React.lazy(() => import("../components/NotFound404"));
const Onboarding = React.lazy(() => import("../pages/Onboarding"));

// components
import Preloader from "../shared/Preloader";
import ProtectedRoute from "./ProtectedRoute";

// helpers
import { departmentPermissionsAiStatements } from "../constants/permissions";

const { serviceExpenses, serviceSales, partsExpenses, partsSales } = departmentPermissionsAiStatements;

const Routes = () => {
  const department = JSON.parse(localStorage.getItem("user")).jobTitle.includes("PARTS")
    ? "parts"
    : "service";

  return (
    <>
      <HeaderMain />
      <Suspense fallback={(
        <div className="app-loading"><Preloader size="large"/></div>
      )}>
        <Switch>
          <Redirect exact path="/" to={`/dashboard/${department}`} />
          <Redirect exact path="/ai-statement" to={`/ai-statement/${department}`} />
          <ProtectedRoute path="/dashboard" permissions={["*"]} component={AIDashboard} />
          <ProtectedRoute path="/ai-reviews" permissions={["DEVELOPER"]} component={AIReviews} />
          <ProtectedRoute
            exact
            path="/ai-statement/service"
            permissions={["*"]}
            departmentsPermissions={[...serviceSales, ...serviceExpenses]}
            component={AIStatementPage}
          />
          <ProtectedRoute
            exact
            path="/ai-statement/parts"
            permissions={["*"]}
            departmentsPermissions={[...partsSales, ...partsExpenses]}
            component={AIStatementPage}
          />
          <ProtectedRoute exact path="/support" permissions={["*"]} component={SupportPage} />
          <ProtectedRoute
            exact
            path="/profile-settings"
            permissions={["*"]}
            component={ProfileSettingsPage}
          />
          <ProtectedRoute
            exact
            path="/notifications"
            permissions={["*"]}
            component={NotificationsPage}
          />
          <ProtectedRoute
            path="/administration"
            permissions={["ADMIN", "ADMIN_SUPER"]}
            component={Administration}
          />
          <ProtectedRoute path="/onboarding" permissions={["*"]} component={Onboarding} />
          <Route path="/404" component={NotFound404} />
          <Redirect to="/404" />
        </Switch>
      </Suspense>
    </>
  );
};

export default Routes;
