/* eslint-disable no-undefined */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */

import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation, useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { BrowserUtils } from "@azure/msal-browser";
import cn from "classnames";
import { Tooltip, notification } from "antd";
import { FiEdit3, FiLogOut } from "react-icons/fi";
import { MdDashboard } from "react-icons/md";
import dayjs from "dayjs";
import {
  RiUserSettingsLine,
  RiQuestionLine,
  RiNotification3Line
} from "react-icons/ri";
import { IoMdCopy, IoMdCheckboxOutline, IoIosWarning } from "react-icons/io";
import { IoClose } from "react-icons/io5";

// elements
import Select from "../../shared/Select";

// helpers
import { useGetUserDealerships, useActiveDealership } from "../../swr/userManagement";
import useProfile from "../../hooks/useProfile";
import { getNotifications } from "../../libs/Header";
import { userHasAccessDepartment, setDashboard } from "../../helpers/permissions";
import notificationsApi from "../../libs/notifications";
import useReportingDate from "../../hooks/useReportingDate";

// constants
import {
  departmentPermissionsDashboard,
  departmentPermissionsAiStatements
} from "../../constants/permissions";

// assets
import defaultAvatar from "../../assets/icons/avatar.svg";
import logo from "../../assets/icons/companyLogo.png";
import IsNotification from "../../assets/IconsComponents/IsNotification";

// styles
import "../../styles/_header.scss";
import "../../styles/components/Header.scss";

const { dashboardService, dashboardParts } = departmentPermissionsDashboard;
const { serviceExpenses, serviceSales, partsExpenses, partsSales } =
  departmentPermissionsAiStatements;

const mainRoutes = [
  {
    to: "/dashboard/",
    icon: <MdDashboard />,
    text: "AI Dashboard",
    roles: ["*"],
    jobTitles: [...dashboardService, ...dashboardParts]
  },
  {
    to: "/ai-statement/",
    icon: <IoMdCopy />,
    text: "AI Statement",
    roles: ["*"],
    jobTitles: [...serviceExpenses, ...serviceSales, ...partsExpenses, ...partsSales]
  },
  {
    to: "/ai-reviews",
    icon: <IoMdCheckboxOutline />,
    text: "AI Reviews",
    roles: ["DEVELOPER"],
    jobTitles: []
  },
];

const MINUTE = 60000;

const MAP_NOTIFICATION_TYPE = {
  "info": "info",
  "warning": "warning",
};

const showNotifications = (notifications, api) => {
  const closedSystemNotifications = JSON.parse(localStorage.getItem("closedSystemNotifications") || "[]");

  notifications.sort(prev => prev.displayType === "ALERT" ? -1 : 1);
  notifications.filter(item => !closedSystemNotifications.includes(item.notificationId)).forEach(item => {
    const type = MAP_NOTIFICATION_TYPE[item.displayType?.toLowerCase()] || "info";

    api[type]({
      key: item.notificationId,
      message: item.link ? <a target="_blank" rel="noreferrer" href={item.link}>{ item.notificationText }</a> : item.notificationText,
      duration: 0,
      closeIcon: <IoClose />,
      icon: type === "warning" ? <IoIosWarning /> : undefined,
      className: `time-system-notification time-system-notification_type-${type}`,
      onClose: () => {
        api.destroy(item.notificationId);

        if (item.notificationId !== "initial-date-selection") {
          const closedNotifications = JSON.parse(localStorage.getItem("closedSystemNotifications") || "[]");
          closedNotifications.push(item.notificationId);
          localStorage.setItem("closedSystemNotifications", JSON.stringify(closedNotifications));
        }
      },
    });
  });
};

const Header = () => {
  const { instance } = useMsal();
  const profileData = useProfile();
  const { data: activeDealership, mutate } = useActiveDealership();

  const [hasNewNotifications, setHasNewNotifications] = useState(false);

  const [[startDateReporting, endDateReporting]] = useReportingDate();

  const location = useLocation();
  const history = useHistory();

  const headerRef = useRef(null);
  const notificationIntervalRef = useRef(null);
  const [api, contextHolder] = notification.useNotification();
  
  const {
    data: userDealerships,
    isLoading,
  } = useGetUserDealerships();

  const isOnboardingPage = location.pathname.includes("/onboarding");
  const isDashboardOpCodes = location.pathname.includes("/dashboard/op-codes");
  const isAdmin = userHasAccessDepartment(["ADMIN", "ADMIN_SUPER"]);

  const selectOptions = userDealerships.map((dealership) => ({
    label: dealership.name,
    value: dealership.id,
    disabled: dealership.disabled
  }));

  const handleChangeActiveDealership = (dealershipName) => {
    const selectedDealership = userDealerships.find((dealership) => dealership.name === dealershipName);

    mutate(selectedDealership);
    
    if (isDashboardOpCodes) {
      history.replace(location.pathname);
    }
  };

  const logOutHandler = () => {
    localStorage.clear();
    setDashboard("service");
    try {
      instance.logoutRedirect({
        account: instance.getActiveAccount(),
        onRedirectNavigate: () => !BrowserUtils.isInIframe()
      });
    } catch {
      return null;
    }
  };

  const checkUserNotifications = () => {
    getNotifications({
      userId: profileData?.id,
      dealershipId: activeDealership.id
    }, "unreadnotifications")
      .then(resp => setHasNewNotifications(resp > 0))
      .catch(() => {
        clearInterval(notificationIntervalRef.current);
        setHasNewNotifications(false);
      });
  };

  const handleNotificationInterval = () => {
    return (notificationIntervalRef.current = setInterval(() => {
      checkUserNotifications();
    }, MINUTE * 5));
  };

  // effects
  useEffect(() => {
    if (isOnboardingPage) {
      return;
    }

    checkUserNotifications();

    process.env.NODE_ENV !== "development" && handleNotificationInterval();

    return () => clearInterval(notificationIntervalRef.current);
  }, [location.pathname, activeDealership.id]);

  useEffect(() => {
    const cachedRef = headerRef.current,
      observer = new IntersectionObserver(
        ([e]) => headerRef.current.classList.toggle("time-header_is-sticky", e.intersectionRatio < 1),
        { threshold: [1] }
      );

    observer.observe(cachedRef);

    // unmount
    return () => observer.unobserve(cachedRef);
  }, [headerRef]);

  useEffect(() => {
    notificationsApi.getSystemNotifications({ userId: profileData.id, dealershipId: activeDealership.id })
      .then(({ data: resp }) => showNotifications(resp, api));
  }, [activeDealership.id]);

  useEffect(() => {
    if (startDateReporting && endDateReporting) {
      showNotifications([{
        notificationId: "initial-date-selection",
        notificationText: `${dayjs(startDateReporting).format("MMM YYYY")} has been selected as the initial date selection on all date selectors for the dashboard.`
      }], api);
    }
  }, [startDateReporting, endDateReporting, location.pathname]);

  // render
  return (
    <header className="time-header" ref={headerRef}>
      { contextHolder }
      <div className="time-header__content">
        <Link
          to="/dashboard/service"
          className={cn("time-header__logo", { ["default"]: isOnboardingPage })}
        >
          <img src={logo} alt="logo" className="time-header__logo-icon" />
        </Link>
        {!isOnboardingPage && (
          <div className="time-header__account">
            <Link to="/profile-settings" className="time-header__account--avatar">
              <img
                src={profileData?.avatar ?? defaultAvatar}
                alt="avatar"
                className="time-header__account--avatar-icon"
              />
              <FiEdit3 className="time-header__account--avatar-edit" />
            </Link>

            <div className="time-header__account--fullname">{profileData?.fullName}</div>
          </div>
        )}
      </div>
      {!isOnboardingPage && (
        <nav className="time-header__navbar">
          {mainRoutes.map((route) => {
            const isAccess = userHasAccessDepartment(route.roles, route.jobTitles);

            return isAccess ? (
              <NavLink key={route.to} to={route.to} className="time-header__navbar--item">
                {route.icon}
                {route.text}
              </NavLink>
            ) : null;
          })}
        </nav>
      )}
      {!isOnboardingPage && (
        <div className="time-header__content">
          <Select
            onChange={e => handleChangeActiveDealership(e.label)}
            value={selectOptions.find((option) => option.value === activeDealership.id)}
            options={selectOptions}
            type="secondary"
            isLoading={isLoading}
          />
          <div className="time-header__helper-bar">
            {isAdmin && (
              <Tooltip title="Administration">
                <NavLink to="/administration" className="time-header__helper-bar--item">
                  <RiUserSettingsLine />
                </NavLink>
              </Tooltip>
            )}
            <Tooltip title="Support">
              <NavLink to="/support" className="time-header__helper-bar--item">
                <RiQuestionLine />
              </NavLink>
            </Tooltip>
            <Tooltip title="Notifications">
              <NavLink to="/notifications" className="time-header__helper-bar--item">
                {hasNewNotifications ? <IsNotification /> : <RiNotification3Line />}
              </NavLink>
            </Tooltip>
            <Tooltip title="Log Out">
              <div
                className={cn("time-header__helper-bar--item", "log-out")}
                onClick={logOutHandler}
              >
                <FiLogOut />
              </div>
            </Tooltip>
          </div>
        </div>
      )}
    </header>
  );
};


export default Header;
