import React from "react";
import { components } from "react-select";
import classNames from "classnames";
import get from "lodash/get";

// components
import Checkbox from "../Checkbox";


const BASE_SELECT_WIDTH = "250px";

export const generateSelectStyles = ({ fullWidth, hasError, type }) => {
  const getContainerStyles = ({ isFocused, isDisabled }) => {
    switch (true) {
      case hasError:
        return {
          border: "1px solid var(--danger-color-400)",
          boxShadow: "0px 0px 0px 2px rgb(255, 228, 230)",
        };
      case isFocused && !isDisabled:
        return {
          border: "1px solid rgba(102, 106, 131, 0.10)",
          boxShadow: "0px 0px 0px 2px rgba(231, 248, 247)",
        };
      default:
        return {
          border: "1px solid rgba(102, 106, 131, 0.1)",
          boxShadow: "0px 1px 2px rgba(31, 41, 55, 0.08)",
        };
    }
  };

  const getContainerHoveredStyles = ({ isFocused, isDisabled }) => {
    switch (true) {
      case hasError:
        return {
          border: "1px solid var(--danger-color-400)",
          boxShadow: "0px 0px 0px 2px rgb(255, 228, 230)",
        };
      case isFocused && !isDisabled:
        return {
          border: "1px solid rgba(102, 106, 131, 0.10)",
          boxShadow: "0px 0px 0px 2px rgba(231, 248, 247)",
        };
      case isDisabled:
        return {
          border: "1px solid var(--gray-color-400)",
          boxShadow: "0px 1px 2px rgba(31, 41, 55, 0.08)",
        };
      default:
        return {
          border: "1px solid rgba(102, 106, 131, 0.1)",
          boxShadow: "0px 0px 0px 2px rgba(229, 231, 235)",
        };
    }
  };

  return {
    container: (stylesProps, state) => ({
      ...stylesProps,
      backgroundColor: !state.isDisabled
        ? type === "secondary" ? "var(--white-color-500)" : "var(--gray-color-200)"
        : "var(--secondary-color-200)",
      width: fullWidth ? "100%" : BASE_SELECT_WIDTH,
      borderRadius: "2px",
    }),
    control: (stylesProps, state) => ({
      ...stylesProps,
      ...getContainerStyles(state),
      backgroundColor: "transparent",
      borderRadius: "2px",
      outline: "none",
      width: "100%",
      minHeight: type === "secondary" ? "30px" : "36px",
      height: type === "secondary" ? "30px" : "36px",
      color: "#969fa4",
      cursor: state.isDisabled ? "not-allowed" : "default",
      pointerEvents: "all",
      opacity: state.isDisabled ? "0.6" : "1",
      transition: "all .3s",
      "&:hover": { ...getContainerHoveredStyles(state), },
    }),
    valueContainer: (stylesProps) => ({
      ...stylesProps,
      fontFamily: "var(--main-font-family)",
      height: type === "secondary" ? "30px" : "36px",
      top: "0",
      padding: "0 10px",
      color: "var(--gray-color-600)",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gridGap: "4px",
      flexWrap: "no-wrap",
    }),
    singleValue: stylesProps => ({
      ...stylesProps,
      fontFamily: "'Inter', sans-serif",
    }),
    menu: stylesProps => ({
      ...stylesProps,
      padding: "8px",
      color: "rgba(255, 255, 255, 0.6)",
      background: type === "secondary" ? "var(--white-color-500)" : "var(--gray-color-200)",
      width: fullWidth ? "100%" : "max-content",
      minWidth: BASE_SELECT_WIDTH,
      borderRadius: "2px",
      maxWidth: fullWidth ? "100%" : "250px",
      zIndex: 10,
      boxShadow: "0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(31, 41, 55, 0.1)",
    }),
    menuList: stylesProps => ({
      ...stylesProps,
      padding: "0px"
    }),
    option: (stylesProps, { isDisabled }) => ({
      ...stylesProps,
      padding: "8px",
      fontFamily: "'Inter', sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      borderRadius: "2px",
      backgroundColor: "transparent !important",
      color: "var(--black-color-500)",
      opacity: isDisabled ? ".5" : "1",
      cursor: isDisabled ? "not-allowed" : "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gridGap: "8px",
      transition: "all .3s",
      "&:hover": { backgroundColor: "rgba(31, 41, 55, 0.08) !important", }
    }),

    indicatorsContainer: stylesProps => ({
      ...stylesProps,
      height: type === "secondary" ? "30px" : "36px",
    }),

    placeholder: stylesProps => ({
      ...stylesProps,
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: "400",
      textTransform: "none"
    }),
  };
};

export const ValueContainer = ({ children, ...props }) => {
  const [values, input] = children;
  let returnValue = values;

  if (Array.isArray(values)) {
    let firstValue = values[0].props.children;

    if (firstValue.includes("Select All") && get(values, "[1].props.children")) {
      firstValue = values[1].props.children;
    }

    returnValue = `${firstValue}${values.length - 1 ? ` +${values.length - 1}` : ""}`;

    if (props.options.length === values.length) {
      returnValue = firstValue;
    }
  }

  return (
    <components.ValueContainer {...props}>
      {returnValue}{input}
    </components.ValueContainer>
  );
};

const getIsChecked = (props) => {
  const optionsToUse = (props.options || []).map(item => item.options ? item.options : item).flat();
  const isAllSelected = optionsToUse.length === props.selectProps.value?.length + 1;

  switch (true) {
    case props.value === "select-all":
      return isAllSelected;
    case props.value === "all-advisors" && !!props.selectProps.value: {
      const advisorOptions = optionsToUse.filter(item => item.key === "advisor");

      return props.selectProps.value.filter(item => item.key === "advisor").length === advisorOptions.length;
    }
    case props.value === "all-technicians" && !!props.selectProps.value: {
      const technicianOptions = optionsToUse.filter(item => item.key === "technician");

      return props.selectProps.value.filter(item => item.key === "technician").length === technicianOptions.length;
    }
    default:
      return props.isSelected;
  }
};

export const Option = (props) => {
  const className = classNames(
    props.className,
    {
      "time-select__option_is-select-all": ["select-all", "all-advisors", "all-technicians"].includes(props.value),
    }
  );

  return (
    <components.Option
      {...props}
      className={className}
    >
      {
        props.isMulti
          ? (
            <Checkbox
              checked={getIsChecked(props)}
              onChange={() => null}
              size="small"
              disabled={props.isDisabled}
              type="secondary"
            />
          )
          : null
      }
  
      <label
        data-select-option-value={props.value}
        className={`time-select-label ${props.value === "missing-tech" ? "red-text" : ""}`}
      >
        {props.label}
      </label>
    </components.Option>
  );
};
