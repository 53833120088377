/* eslint-disable react/display-name */
import { SWRConfig } from "swr";
import { ConfigProvider } from "antd";
import { MsalProvider } from "@azure/msal-react";

// helpers
import axios from "../libs/Api";
import Cache from "../helpers/Cache";


const SWR_CONFIG = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  revalidateOnMount: true,
  shouldRetryOnError: false,
  provider: () => new Cache(),
  fetcher: (url) => axios.get(url, { baseURL: process.env.REACT_APP_API_URL }).then((resp) => resp.data),
};

const withRootSettings = (Component) => {
  return props => {
    return (
      <MsalProvider instance={props.instance}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "rgb(0, 105, 255)",
              colorSuccess: "rgba(91, 190, 43, 1)",
              colorError: "rgba(241, 91, 64, 1)"
            }
          }}
        >
          <SWRConfig value={SWR_CONFIG}>
            <Component {...props} />
          </SWRConfig>
        </ConfigProvider>
      </MsalProvider>
    );
  };
};

export default withRootSettings;
