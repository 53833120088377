import React from "react";
import ReactDOM from "react-dom";

// components
import App from "./App";

// helpers
import { unregister } from "./serviceWorker";
import { msalInitialize } from "./helpers/msal";
import { setUserData } from "./helpers/msalSerializer";

// assets
import "./index.scss";

msalInitialize().then(({ instance, user }) => {
  setUserData(user);
  ReactDOM.render(<App instance={instance}/>, document.getElementById("root"));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
