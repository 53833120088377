/**
 * Checks if user has passed login
 * @return {boolean} - Result
 */
export const userIsAuthenticated = () => !!localStorage.getItem("user");

/**
 * Checks if user has permission to have access to some functionality
 * Valid user roles: *(for all), ADMIN, ADMIN_SUPER, MANAGER, MANAGER_SUPER, EMPLOYEE, CONSULTANT
 * Valid user jobTitles: *(for all), SERVICE, PARTS
 * @param {string[]} roles - Array with roles which should have access
 * @param {string[]} jobTitles - Array with job Titles which should have access
 * @return {boolean} - Result
 */
export const userHasAccessDepartment = (roles, jobTitles = []) => {
  if (!userIsAuthenticated()) return false;

  const userData = JSON.parse(localStorage.getItem("user"));
  const department = userData.jobTitle;

  if (userData.devFeatures) return true;

  if (jobTitles.length) {
    if (roles.includes("*")) {
      if (jobTitles.includes("*")) return true;
      if (jobTitles.includes(department)) return true;
    }

    return roles.includes(userData.role) && (jobTitles.includes("*") || jobTitles.includes(department));
  }

  if (roles.includes("*")) return true;

  return roles.includes(userData.role);
};

/**
 * Gets a data of current user
 * @return {object} - User data
 * @return {boolean} - User data not found in local storage
 */
export const getCurrentUser = () => {
  try {
    return JSON.parse(localStorage.getItem("user"));
  } catch (e) {
    return false;
  }
};

/**
 * Checks if user has passed login
 * @param {string} type - type of dashboard
 * @return {void} - Result
 */
export const setDashboard = (type) => localStorage.setItem("dashboard", type);

/**
 * Checks if user has passed login
 * @return {string} - Result
 */
export const getDashboard = () => localStorage.getItem("dashboard") || "service";

/**
 * Set selected dealer info
 * @param {Object} obj - dealer data
 * @return {void} - Result
 */
export const setDealer = (obj) => localStorage.setItem("dealer", JSON.stringify(obj));

/**
 * Get selected dealer info
 * @return {object} - Result
 */
export const getDealer = () => JSON.parse(localStorage.getItem("dealer")) || {};

export const isDev = () => {
  const userData = JSON.parse(localStorage.getItem("user"));

  return userData.devFeatures;
};
