import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

// components
import Routes from "./router/routes";

//elements
import Preloader from "./shared/Preloader";

// hoc
import withRootSettings from "./hoc/withRootSettings";

//helpers
import { updateEmployee } from "./libs/User";
import { useGetUser, useGetUserDealerships } from "./swr/userManagement";


function App() {
  const {
    data: currentUser,
    mutate,
  } = useGetUser();
  const { data } = useGetUserDealerships({ config: { enabled: !!currentUser?.id } });

  useEffect(() => {
    if (!isEmpty(currentUser) && !currentUser.isVerified) {
      updateEmployee(
        { data: { userId: currentUser.localAccountId, status: true } },
        "userAccountStatus"
      ).then(() => mutate());
    }
  }, [currentUser]);


  if (
    currentUser?.id &&
    data?.length
  ) {
    return (
      <div className="App">
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </div>
    );
  }

  return (
    <div className="app-loading">
      <Preloader size="large" />
    </div>
  );
}

export default withRootSettings(App);
