import React, { useState, useEffect } from "react";

// assets
import ChevronUp from "../assets/IconsComponents/ChevronUp";
import "../styles/elements/ScrollToTop.scss";

export default function ScrollToTop() {
  const [display, setDisplay] = useState(false);

  const checkPosition = () => {
    setDisplay(window.pageYOffset > 400);
  };

  useEffect(() => window.addEventListener("scroll", checkPosition), []);

  return (
    <div
      className={`scroll-to-top ${display ? "" : "scroll-to-top__hidden"}`}
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      <ChevronUp width={"100%"} height={"100%"} color={"#FFF"} />
    </div>
  );
}
