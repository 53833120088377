export function buildQueryString(params = {}) {
  const query = new URLSearchParams();

  Object.keys(params).forEach((key) => {
    if (params[key]) {
      query.set(key, params[key]);
    }
  });

  const queryString = query.toString();

  return queryString ? `?${queryString}` : "";
}
