export const departmentPermissionsDashboard = {
  technicianMapping: ["ADMIN_SUPER", "ADMIN"],
  dashboardService: [
    "DEALER_PRINCIPLE",
    "GENERAL_MANAGER",
    "OFFICE_MANAGER",
    "OFFICE_OTHER",
    "WARRANTY_ADMIN",
    "FIXED_OPS_DIRECTOR",
    "SERVICE_DIRECTOR",
    "SERVICE_MANAGER",
    "ASST_SERVICE_MANAGER",
    "SERVICE_ADVISOR",
    "SERVICE_TECHNICIAN",
    "SERVICE_OTHER",
    "PARTS_DIRECTOR",
    "DETAIL_TECHNICIAN",
    "BDC_MANAGER",
    "BDC_ASSOCIATE",
    "CONSULTANT"
  ],
  dashboardParts: [
    "DEALER_PRINCIPLE",
    "GENERAL_MANAGER",
    "OFFICE_MANAGER",
    "OFFICE_OTHER",
    "FIXED_OPS_DIRECTOR",
    "SERVICE_DIRECTOR",
    "PARTS_DIRECTOR",
    "PARTS_MANAGER",
    "ASST_PARTS_MANAGER",
    "PARTS_WHOLESALE",
    "PARTS_OTHER",
    "BDC_MANAGER",
    "BDC_ASSOCIATE",
    "CONSULTANT"
  ],
  allEmployeeView: [
    "DEALER_PRINCIPLE",
    "GENERAL_MANAGER",
    "OFFICE_MANAGER",
    "OFFICE_OTHER",
    "WARRANTY_ADMIN",
    "FIXED_OPS_DIRECTOR",
    "SERVICE_DIRECTOR",
    "SERVICE_MANAGER",
    "ASST_SERVICE_MANAGER",
    "SERVICE_OTHER",
    "PARTS_DIRECTOR",
    "PARTS_MANAGER",
    "ASST_PARTS_MANAGER",
    "PARTS_WHOLESALE",
    "PARTS_OTHER",
    "BDC_MANAGER",
    "BDC_ASSOCIATE",
    "CONSULTANT"
  ]
};

export const DASHBOARD_JOB_TITLE_PERMISSIONS = {
  technicianMapping: ["ADMIN_SUPER", "ADMIN"],
  dashboardService: [
    "DEALER_PRINCIPLE",
    "GENERAL_MANAGER",
    "OFFICE_MANAGER",
    "OFFICE_OTHER",
    "WARRANTY_ADMIN",
    "FIXED_OPS_DIRECTOR",
    "SERVICE_DIRECTOR",
    "SERVICE_MANAGER",
    "ASST_SERVICE_MANAGER",
    "SERVICE_ADVISOR",
    "SERVICE_TECHNICIAN",
    "SERVICE_OTHER",
    "PARTS_DIRECTOR",
    "DETAIL_TECHNICIAN",
    "BDC_MANAGER",
    "BDC_ASSOCIATE",
    "CONSULTANT"
  ],
  dashboardParts: [
    "DEALER_PRINCIPLE",
    "GENERAL_MANAGER",
    "OFFICE_MANAGER",
    "OFFICE_OTHER",
    "FIXED_OPS_DIRECTOR",
    "SERVICE_DIRECTOR",
    "PARTS_DIRECTOR",
    "PARTS_MANAGER",
    "ASST_PARTS_MANAGER",
    "PARTS_WHOLESALE",
    "PARTS_OTHER",
    "BDC_MANAGER",
    "BDC_ASSOCIATE",
    "CONSULTANT"
  ],
  laborMatrix: [
    "DEALER_PRINCIPLE",
    "GENERAL_MANAGER",
    "FIXED_OPS_DIRECTOR",
    "OFFICE_MANAGER",
    "WARRANTY_ADMIN",
    "SERVICE_DIRECTOR",
    "SERVICE_MANAGER",
    "ASST_SERVICE_MANAGER",
    "PARTS_DIRECTOR",
    "PARTS_MANAGER",
    "ASST_PARTS_MANAGER",
    "CONSULTANT",
  ],
  allEmployeeView: [
    "DEALER_PRINCIPLE",
    "GENERAL_MANAGER",
    "OFFICE_MANAGER",
    "OFFICE_OTHER",
    "WARRANTY_ADMIN",
    "FIXED_OPS_DIRECTOR",
    "SERVICE_DIRECTOR",
    "SERVICE_MANAGER",
    "ASST_SERVICE_MANAGER",
    "SERVICE_OTHER",
    "PARTS_DIRECTOR",
    "PARTS_MANAGER",
    "ASST_PARTS_MANAGER",
    "PARTS_WHOLESALE",
    "PARTS_OTHER",
    "BDC_MANAGER",
    "BDC_ASSOCIATE",
    "CONSULTANT"
  ]
};

export const departmentPermissionsAiStatements = {
  serviceSales: [
    "DEALER_PRINCIPLE",
    "GENERAL_MANAGER",
    "OFFICE_MANAGER",
    "OFFICE_OTHER",
    "WARRANTY_ADMIN",
    "FIXED_OPS_DIRECTOR",
    "SERVICE_DIRECTOR",
    "SERVICE_MANAGER",
    "ASST_SERVICE_MANAGER",
    "SERVICE_OTHER",
    "PARTS_DIRECTOR",
    "PARTS_MANAGER"
  ],
  serviceExpenses: [
    "DEALER_PRINCIPLE",
    "GENERAL_MANAGER",
    "OFFICE_MANAGER",
    "OFFICE_OTHER",
    "WARRANTY_ADMIN",
    "FIXED_OPS_DIRECTOR",
    "SERVICE_DIRECTOR",
    "SERVICE_MANAGER",
    "ASST_SERVICE_MANAGER",
    "SERVICE_OTHER",
    "PARTS_DIRECTOR",
    "PARTS_MANAGER"
  ],
  partsSales: [
    "DEALER_PRINCIPLE",
    "GENERAL_MANAGER",
    "OFFICE_MANAGER",
    "OFFICE_OTHER",
    "WARRANTY_ADMIN",
    "FIXED_OPS_DIRECTOR",
    "SERVICE_DIRECTOR",
    "PARTS_DIRECTOR",
    "PARTS_MANAGER",
    "ASST_PARTS_MANAGER",
    "PARTS_OTHER"
  ],
  partsExpenses: [
    "DEALER_PRINCIPLE",
    "GENERAL_MANAGER",
    "OFFICE_MANAGER",
    "OFFICE_OTHER",
    "WARRANTY_ADMIN",
    "FIXED_OPS_DIRECTOR",
    "SERVICE_DIRECTOR",
    "PARTS_DIRECTOR",
    "PARTS_MANAGER",
    "ASST_PARTS_MANAGER",
    "PARTS_OTHER"
  ]
};

export const DEPARTMENTS = [
  { key: "Service", value: "Service" },
  { key: "Parts", value: "Parts" },
  { key: "Corporate", value: "Corporate" }
];

export const ROLES = [
  {
    key: "ADMIN",
    value: "Admin",
    tooltip: "Single Dealership | Multi-Employee View | Editing Capabilities"
  },
  {
    key: "ADMIN_SUPER",
    value: "Super Admin",
    tooltip: "Multi-Dealership | Multi-Employee View | Editing Capabilities"
  },
  {
    key: "MANAGER",
    value: "Manager",
    tooltip: "Single Dealership | Multi-Employee View"
  },
  {
    key: "MANAGER_SUPER",
    value: "Super Manager",
    tooltip: "Multi-Dealership | Multi-Employee View"
  },
  {
    key: "EMPLOYEE",
    value: "Employee",
    tooltip: "Single or Multi-Dealership | Single-Employee View"
  },
  {
    key: "CONSULTANT",
    value: "Consultant",
    tooltip: "Single or Multi-Dealership | Read Only Access"
  }
];

export const JOB_TITLES = [
  { key: "DEALER_PRINCIPLE", value: "Dealer Principle" },
  { key: "GENERAL_MANAGER", value: "General Manager" },
  { key: "OFFICE_MANAGER", value: "Office Manager" },
  { key: "OFFICE_OTHER", value: "Office - Other" },
  { key: "WARRANTY_ADMIN", value: "Warranty Admin" },
  { key: "FIXED_OPS_DIRECTOR", value: "Fixed OPS Director" },
  { key: "SERVICE_DIRECTOR", value: "Service Director" },
  { key: "SERVICE_MANAGER", value: "Service Manager" },
  { key: "ASST_SERVICE_MANAGER", value: "Asst. Service Manager" },
  { key: "SERVICE_ADVISOR", value: "Service Advisor" },
  { key: "SERVICE_TECHNICIAN", value: "Service Technician" },
  { key: "DETAIL_TECHNICIAN", value: "Detail Technician" },
  { key: "SERVICE_OTHER", value: "Service - Other" },
  { key: "PARTS_DIRECTOR", value: "Parts Director" },
  { key: "PARTS_MANAGER", value: "Parts Manager" },
  { key: "ASST_PARTS_MANAGER", value: "Asst. Parts Manager" },
  { key: "PARTS_WHOLESALE", value: "Parts Wholesale" },
  { key: "PARTS_OTHER", value: "Parts - Other" },
  { key: "BDC_MANAGER", value: "BDC Manager" },
  { key: "BDC_ASSOCIATE", value: "BDC Associate" },
  { key: "CONSULTANT", value: "Consultant" }
];
