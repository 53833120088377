class Cache extends Map {
  remove (key) {
    const allKey = [...this.keys()];
    const keysToDelete = allKey.filter(item => item.includes(key));

    keysToDelete.forEach(item => {
      this.delete(item);
    });
  }
}

export default Cache;
