import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// assets
import chameleonAnimation from "../assets/preloader/tai-preloader.svg";
import "../styles/shared/preloader.scss";


function Preloader({ className, size = "medium", ...rest }) {
  const preloaderClassName = classNames(
    "time-preloader",
    className,
    `time-preloader_size-${size}`
  );

  return (
    <div className={preloaderClassName} data-testid="time-preloader" { ...rest }>
      <object
        className="time-preloader__inner"
        type="image/svg+xml"
        data={chameleonAnimation}
      >
        svg-animation
      </object>
    </div>
  );
}

Preloader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

export default Preloader;
