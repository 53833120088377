export const setAnalyticProperties = (user) => {
  const callAnalytics = window.heap;
  if (callAnalytics) {
    const { identify, addUserProperties } = callAnalytics;

    identify(user.email);
    addUserProperties({
      firstName: user.firstName,
      lastName: user.lastName,
      employeeId: user.id,
      role: user.role,
      jobTitle: user.jobTitle,
      dealershipGroup: user.dealershipGroup
    });
  }
};
