/* eslint-disable no-useless-catch */

import axios, { authApiConfig } from "./Api";


export async function createUser({ data }) {
  const result = await axios.post("/CreateUser", data, authApiConfig);

  return result.data;
}

export async function updateEmployee({ data }, type) {
  try {
    const result = await axios.patch(type, data, authApiConfig);

    return result.data;
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
}

export async function uploadAvatarToServer(data) {
  const result = await axios.patch("/updateAvatarUrl", data, authApiConfig);

  return result.data;
}

export async function reInviteUser({ body }) {
  const response = await axios.post("resendInviteEmail", body, authApiConfig);

  return response.data;
}

export async function softDeleteUser({ body }) {
  try {
    const response = await axios.patch("SoftDeleteUser", body, authApiConfig);

    return response.data;
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
}

export async function unSoftDeleteUser({ body }) {
  try {
    const response = await axios.patch("UnSoftDeleteUser", body, authApiConfig);

    return response.data;
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
}

export async function updateEmployeeRequest(body) {
  try {
    const response = await axios.patch("updateUsersDealerships", body, authApiConfig);

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getTaiUser({ localAccountId }) {
  const url = `/GetUser?userId=${localAccountId}`;
  const response = await axios.get(url, authApiConfig);

  return response.data.value[0];
}

export async function getUserDealerships(localAccountId) {
  const url = `/GetUserDealershipMembership?userId=${localAccountId}`;
  const response = await axios.get(url, authApiConfig);

  return response.data;
}

export const updateDefaultDealership = async ({ body }) => {
  try {
    const response = await axios.patch("/UpdateUserDefaultDealership", body, authApiConfig);

    return response;
  } catch (error) {
    throw error;
  }
};
