import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL;

const getAuthToken = () => {
  const token = localStorage.getItem("accessToken");

  // eslint-disable-next-line no-undefined
  return token ? `Bearer ${token}` : undefined;
};

const baseConfig = {
  baseURL: `${BASE_URL}/api/`,
  responseType: "json",
  headers: {
    "TAI-Sub-Key": process.env.REACT_APP_API_KEY
  }
};

const baseInterceptor = (config) => {
  const token = getAuthToken();

  if (token) {
    config.headers.Authorization = token;
  }

  return config;
};

const baseAxios = axios.create(baseConfig);
baseAxios.interceptors.request.use(baseInterceptor);

export default baseAxios;

export const refetchToken = async (error, instance, requestScopes, accounts) => {
  const originalRequest = error.config;

  if (error.response.status === 401 && !originalRequest.__isRetryRequest) {
    originalRequest.__isRetryRequest = true;
    const data = await instance.acquireTokenSilent({ ...requestScopes, account: accounts[0] });

    if (data) {
      localStorage.setItem("accessToken", data.accessToken);
    }

    originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;

    const response = await baseAxios(originalRequest);

    return response;
  }

  return Promise.reject(error);
};

export const authApiConfig = {
  baseURL: `${BASE_URL}/usermgmt/`,
  headers: {
    ...baseConfig.headers,
    "TAI-Sub-Key": process.env.REACT_APP_USER_MANAGEMEMT_API_KEY
  }
};
